<template>
    <div
        :class="{
            'depth-gutter': depth > 0,
            'preview-before': hoverState.dt1,
            'preview-after': hoverState.dt2,
        }"
        v-show="visible"
        :draggable="draggable"
        @dragstart.stop="onDragStart"
        @dragend.stop="onDragEnd"
    >
        <div
            class="arbo-node-block"
            :class="{
                root: depth == 0,
                dragged: isDragged,
                templated,
                selected,
            }"
            @dragenter.stop="onDragEnter($event, 'main')"
            @dragleave.stop="onDragLeave($event, 'main')"
            @click="selectMe"
        >
            <template v-if="depth == 0">
                <div>
                    Arborescence
                </div>
                <div class="flex-grow-1" />
            </template>
            <template v-else>
                <template v-if="templated">
                    <div class="arbo-node-pill bg-kalkin-1">
                        <div>- Modèle -</div>
                    </div>
                    {{ menu.templateId }}
                </template>
                <template v-else>
                    <div
                        class="drag-handle"
                        @mouseenter="draggable = true"
                        @mouseleave="draggable = false"
                    >
                        <b-icon-grip-vertical />
                    </div>
                    <div
                        class="drop-menu"
                        :class="{ expanded: hoverState.main }"
                    >
                        <div class="drop-menu-help">
                            Déposer le menu pour choisir sa position
                        </div>
                        <div
                            class="drop-target"
                            :class="{ hover: hoverState.dt1 }"
                            @dragenter="onDragEnter($event, 'dt1')"
                            @dragleave="onDragLeave($event, 'dt1')"
                            @dragover.prevent
                            @drop="onDrop($event, 'dt1')"
                        >
                            <b-icon-menu-button-wide-fill />
                            <div>Avant</div>
                        </div>

                        <div
                            class="drop-target"
                            :class="{ hover: hoverState.dt2 }"
                            @dragenter="onDragEnter($event, 'dt2')"
                            @dragleave="onDragLeave($event, 'dt2')"
                            @dragover.prevent
                            @drop="onDrop($event, 'dt2')"
                        >
                            <b-icon-menu-button-wide-fill rotate="180" />
                            <div>Après</div>
                        </div>

                        <div
                            class="drop-target"
                            :class="{ hover: hoverState.dt3 }"
                            @dragenter="onDragEnter($event, 'dt3')"
                            @dragleave="onDragLeave($event, 'dt3')"
                            @dragover.prevent
                            @drop="onDrop($event, 'dt3')"
                        >
                            <b-icon-menu-button-fill rotate="180" />
                            <div>Enfant</div>
                        </div>
                    </div>
                </template>

                <div class="arbo-node-pill">
                    <div>Niveau {{ depth }}</div>
                </div>
                <div style="flex-grow:1">
                    <div class="k-flex-row k-gap-none">
                        <small class="text-kalkin-2"> Nom du menu</small>
                        <small
                            class="rounded bg-kalkin-3 px-2 text-light"
                            v-show="menuType"
                        >
                            <b-icon-tag-fill /> {{ menuType }}
                        </small>
                    </div>
                    <b-input
                        size="sm"
                        type="text"
                        v-model="name"
                        @blur="onNameChange"
                        @keydown.enter.prevent="$event.target.blur()"
                        trim
                    />
                </div>
                <div style="flex-grow:2" />
                <div
                    v-show="isHidden"
                    v-b-tooltip.hover.top
                    title="Le menu est masqué"
                >
                    <b-icon-eye-slash />
                </div>
                <div
                    v-show="menu.notes"
                    v-b-tooltip.hover.top
                    title="Le menu possède des notes"
                >
                    <b-icon-file-text />
                </div>
                <div
                    v-show="menu.dataSelectionCount > 0"
                    v-b-tooltip.hover.top
                    title="Le menu est associé a des flux de données"
                >
                    <b-icon-box />
                </div>
                <div
                    class="box-i18n"
                    v-show="langCount > 0"
                    v-b-tooltip.hover.top
                    title="Traduction"
                >
                    <b-icon-translate
                        class="mr-1"
                        :class="{
                            'text-danger': langCount > menu.translationCount,
                            'text-kalkin-2': langCount == menu.translationCount,
                        }"
                    />
                    <small>{{ menu.translationCount }}/{{ langCount }}</small>
                </div>
            </template>
            <div>
                <b-button
                    size="xs"
                    variant="outline-kalkin-1"
                    @click.stop="addMenu"
                    @mouseenter="hoverState.add = true"
                    @mouseleave="hoverState.add = false"
                >
                    <b-icon-plus-square-fill />
                </b-button>
            </div>
        </div>
        <div
            class="arbo-node-children"
            v-if="hasChildren"
            @dragenter.stop
            @dragleave.stop
        >
            <div
                class="arbo-node-control pointer user-select-none"
                :class="{ expanded: showChildren }"
                v-if="menu.children.length"
                @click="showChildren = !showChildren"
            >
                <span v-show="!showChildren">
                    <b-icon-plus-square-fill /> Afficher les sous-menus masqués
                    (niveau {{ depth + 1 }} et plus)
                </span>
                <span v-show="showChildren">
                    <b-icon-dash-square-fill />
                </span>
            </div>

            <template v-for="(menu, index) in menu.children">
                <ArboNode
                    :arbo="arbo"
                    :menu="menu"
                    :index="index"
                    :key="menu.id"
                    :depth="depth + 1"
                    :visible="showChildren"
                    :has-ancestor-dragged="isDragged"
                    :has-ancestor-hidden="isHidden"
                    :selected-menu-id="selectedMenuId"
                    @action="onAction"
                />
            </template>
        </div>
        <div
            class="depth-gutter"
            :class="{
                'preview-before': hoverState.dt3 || hoverState.add,
            }"
        />
    </div>
</template>

<script>
import MenuData from "@/model/menu";

export default {
    name: "ArboNode",

    props: {
        arbo: Object,
        menu: Object,
        index: Number,
        depth: { type: Number, default: 0 },
        visible: { type: Boolean, default: true },
        hasAncestorDragged: { type: Boolean, default: false },
        hasAncestorHidden: { type: Boolean, default: false },
        selectedMenuId: [String, Number],
    },

    data: function() {
        return {
            name: this.menu.name,
            hasChildren: this.menu.children && this.menu.children.length > 0,
            showChildren: true,

            draggable: false, //Si la poignée de drag est hover
            dragged: false, //Si l'objet est actuellement dragged
            hoverState: {
                main: false,
                dt1: false,
                dt2: false,
                dt3: false,
                add: false,
            },
        };
    },

    computed: {
        menuType() {
            return MenuData.getTypeLabel(
                this.menu.config && this.menu.config.type,
            );
        },

        isDragged() {
            return this.hasAncestorDragged || this.dragged;
        },

        templated() {
            return this.menu && this.menu.templateId;
        },

        selected() {
            return this.depth > 0 && this.selectedMenuId === this.menu.id;
        },

        isHidden() {
            if (this.depth > 0) {
                return this.hasAncestorHidden || !this.menu.isVisible;
            } else {
                return false;
            }
        },

        langCount() {
            if (this.arbo) {
                return this.arbo.Langs.filter(l => l.id !== "fr").length;
            }
            return 0;
        },
    },

    watch: {
        menu: {
            handler() {
                this.hasChildren =
                    this.menu.children && this.menu.children.length > 0;
            },
            deep: true,
        },
    },

    methods: {
        onAction(payload) {
            this.$emit("action", payload);
        },

        onDragStart(event) {
            if (this.draggable) {
                this.dragged = true;
                event.dataTransfer.setData("menuId", this.menu.id);
            }
        },

        onDragEnd() {
            this.dragged = false;
        },

        onDragEnter(event, target) {
            if (!this.isDragged) {
                this.hoverState[target] = true;
            } else {
                event.stopPropagation();
            }
        },

        onDragLeave(event, target) {
            if (!this.isDragged) {
                if (!event.currentTarget.contains(event.relatedTarget)) {
                    this.hoverState[target] = false;
                    if (target === "main") {
                        Object.keys(this.hoverState).forEach(
                            v => (this.hoverState[v] = false),
                        );
                    }
                }
            } else {
                event.stopPropagation();
            }
        },

        onDrop(event, target) {
            const menuId = event.dataTransfer.getData("menuId");
            if (menuId) {
                let parentId, order;
                if (target === "dt1") {
                    parentId = this.menu.parentId;
                    order = this.index;
                } else if (target === "dt2") {
                    parentId = this.menu.parentId;
                    order = this.index + 1;
                } else {
                    parentId = this.menu.id;
                    order = this.menu.children.length;
                }
                this.$emit("action", {
                    action: "MoveMenu",
                    data: { menuId, parentId, order },
                });
            }
            Object.keys(this.hoverState).forEach(
                v => (this.hoverState[v] = false),
            );
        },

        selectMe() {
            if (this.depth > 0) {
                this.$emit("action", {
                    action: "SelectMenu",
                    data: this.menu.id,
                });
            }
        },

        addMenu() {
            this.showChildren = true;
            this.$emit("action", {
                action: "AddMenu",
                data: { parentId: this.menu.id },
            });
        },

        onNameChange() {
            if (this.name === "") {
                this.name = this.menu.name;
            } else if (this.name !== this.menu.name) {
                this.$emit("action", {
                    action: "UpdateMenu",
                    data: { menuId: this.menu.id, name: this.name },
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.drag-handle {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 40px;
    width: 20px;
    border: 1px solid #ddd;
    border-radius: 6px;

    background: #e5e5e5;
    font-size: 25px;
    color: $primary-color;

    cursor: move;
}

.arbo-node-block.dragged {
    opacity: 0.3 !important;

    transition: opacity 0.5s ease;
}
.arbo-node-block.flew-over {
    background: #ccc;
}

.drop-menu {
    display: flex;
    align-items: flex-end;
    justify-content: center;

    width: 0px;
    height: 55px;
    border-radius: 5px;

    overflow: hidden;

    transition: width 0.4s ease;
}
.drop-menu.expanded {
    max-width: 450px;
    width: 50%;
    border: 1px solid $primary-color;
}

.drop-menu-help {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-basis: 0;
    flex-grow: 1;

    height: 100%;

    text-align: center;
    font-size: 9px;
    color: $primary-color;
}

.drop-target {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-basis: 0;
    flex-grow: 1;

    height: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;

    font-size: 20px;
    background: $primary-color;
    color: #ccc;

    transition: background 0.3s, color 0.3s;
}

.drop-target > div {
    font-size: 10px;
}

.drop-target.hover {
    background: #ccc;
    color: $primary-color;
    box-shadow: inset 1px 1px #222, inset -1px -1px #eee;
}

.drop-target * {
    pointer-events: none;
}

.preview {
    display: block;
    position: absolute;

    width: 200px;
    height: 10px;
    margin-top: -5px;
    padding-right: 16px;
    line-height: 10px;

    font-size: 9px;
    text-align: right;
    color: white;

    background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='200' height='10' viewBox='-3 -5 200 10'><line x1='0' y1='0' x2='190' y2='0' stroke='rgb(0, 129, 123)' stroke-width='2px'/><line x1='100' y1='0' x2='190' y2='0' stroke='rgb(0, 129, 123)' stroke-width='10px' stroke-linecap='round'/><circle cx='0' cy='0' r='3' fill='rgb(0, 64, 61)'/></svg>");
}

.preview-before::before {
    content: "Position du menu";
    @extend .preview;
}

.preview-after::after {
    content: "Position du menu";
    @extend .preview;
}

.box-i18n {
    padding: 0 10px;
    border: 1px solid #ddd;
    border-radius: 6px;

    background: #e5e5e5;
}
</style>
