<template>
    <SplitPanel initial-mode="0">
        <template v-slot:left>
            <TreeArboStructure
                :arbo="arbo"
                :root="arboRoot"
                :selected-menu-id="selectedMenu.id"
                @action="onArboAction"
            />
        </template>
        <template v-slot:menu>
            <ToolboxArboMenu :projectId="projectId" :arbo="arbo" :current="0" />
        </template>
        <template v-slot:right>
            <div class="menu">
                <template v-if="!selectedMenu.id">
                    <div class="text-kalkin-1">Aucun menu selectionné</div>
                </template>
                <template v-else>
                    <div class="my-1 text-center">
                        <small>Edition du menu</small>
                        <div class="text-kalkin-1">
                            {{ selectedMenu.name }}
                        </div>
                    </div>
                    <div class="panel k-flex-col">
                        <b-form-group>
                            <label> Notes de conception </label>
                            <b-form-textarea
                                v-model="selectedMenu.notes"
                                placeholder="Pas de note"
                                rows="4"
                                max-rows="20"
                                size="sm"
                                @change="doUpdateMenu(selectedMenu)"
                            />
                        </b-form-group>

                        <b-form-group>
                            <label> Visibilité du menu </label>
                            <b-form-checkbox
                                class="k-switch"
                                v-model="selectedMenu.isVisible"
                                @change="doUpdateMenu(selectedMenu)"
                                switch
                            >
                                <small v-if="selectedMenu.isVisible">
                                    Visible
                                </small>
                                <small v-else>Non visible</small>
                            </b-form-checkbox>

                            <small class="text-secondary">
                                <b-icon-question-circle /> Permet de masquer un
                                menu et sa descendance sans les supprimer
                            </small>
                        </b-form-group>

                        <b-form-group>
                            <label> Traductions </label>
                            <FormTranslation
                                :projectId="projectId"
                                :arbo="arbo"
                                :menu="selectedMenu"
                                @updated="fetchArbo"
                            />
                        </b-form-group>
                    </div>
                    <div class="my-1 text-center">
                        <small>
                            <b-icon-gear-fill /> Edition avancée du menu
                        </small>
                    </div>
                    <div class="panel k-flex-col">
                        <FormAdvanced
                            :menu="selectedMenu"
                            @update-menu="doUpdateMenu"
                        />
                    </div>
                    <div class="flex-grow-1" />
                    <div class="panel text-center">
                        <b-button
                            size="sm"
                            variant="outline-danger"
                            v-b-modal.ModalConfirmDelete
                        >
                            Supprimer le menu
                        </b-button>
                    </div>
                </template>
                <ModalConfirmDelete @confirmed="onDeleteConfirmed" />
            </div>
        </template>
    </SplitPanel>
</template>

<script>
import SplitPanel from "@/components/ui/SplitPanel";
import TreeArboStructure from "@/components/model/arbo/TreeArboStructure";
import ToolboxArboMenu from "@/components/model/arbo/ToolboxArboMenu";
import FormTranslation from "@/components/model/menu/form/structure/FormTranslation";
import FormAdvanced from "@/components/model/menu/form/structure/FormAdvanced";
import ModalConfirmDelete from "@/components/modal/ModalConfirmDelete";

export default {
    components: {
        SplitPanel,
        TreeArboStructure,
        ToolboxArboMenu,
        FormTranslation,
        FormAdvanced,
        ModalConfirmDelete,
    },

    data: function() {
        return {
            projectId: this.$route.params.projectId,
            arboId: this.$route.params.arboId,

            arbo: undefined,
            arboRoot: null,
            menus: {},
            templateConfig: [],

            selectedMenu: {
                id: null,
                notes: "",
                templateId: "",
                config: {},
            },
        };
    },

    methods: {
        async fetchArbo() {
            this.arbo = await this.$store.dispatch("project/getArbo", {
                projectId: this.projectId,
                arboId: this.arboId,
            });

            const raw = await this.$store.dispatch("arbo/getMenus", {
                projectId: this.projectId,
                arboId: this.arboId,
            });

            this.menus = raw.reduce((acc, cur) => {
                cur.children = [];
                acc[cur.id] = cur;
                return acc;
            }, {});

            this.arboRoot = [];
            for (const menu of raw) {
                if (menu.parentId) {
                    this.menus[menu.parentId].children.push(menu);
                } else {
                    this.arboRoot.push(menu); //roots
                }
            }
        },

        async onArboAction({ action, data }) {
            await this[`do${action}`](data);
        },

        async doMoveMenu({ menuId, parentId, order }) {
            await this.$store.dispatch("arbo/moveMenu", {
                projectId: this.projectId,
                menuId,
                parentId,
                order,
            });
            await this.fetchArbo();
        },

        async doAddMenu({ parentId }) {
            if (parentId) {
                await this.$store.dispatch("arbo/addChildMenu", {
                    projectId: this.projectId,
                    menuId: parentId,
                    name: "Nouveau menu",
                });
            } else {
                await this.$store.dispatch("arbo/addRootMenu", {
                    projectId: this.projectId,
                    arboId: this.arboId,
                    name: "Nouveau menu",
                });
            }
            await this.fetchArbo();
        },

        async doUpdateMenu(payload) {
            payload.menuId = payload.menuId || payload.id;
            await this.$store.dispatch("arbo/updateMenu", {
                projectId: this.projectId,
                ...payload,
            });
            await this.fetchArbo();
        },

        async doDeleteMenu({ menuId }) {
            await this.$store.dispatch("arbo/deleteMenu", {
                projectId: this.projectId,
                menuId,
            });
            this.selectedMenu = {};
            await this.fetchArbo();
        },

        doSelectMenu(id) {
            const rightContainer = document.getElementById("k-sp-right")
                .children[0];
            rightContainer.scrollTop = 0;
            if (this.selectedMenu.id !== id) {
                this.selectedMenu = Object.assign({}, this.menus[id]);
            } else {
                this.selectedMenu = {};
            }
        },

        async onDeleteConfirmed() {
            await this.doDeleteMenu({ menuId: this.selectedMenu.id });
        },
    },

    async beforeMount() {
        await this.fetchArbo();
    },
};
</script>

<style lang="scss" scoped>
.menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    min-height: 100%;
    padding: 8px 0;

    background: #eee;
}

.panel {
    width: calc(100% - 16px);
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;

    background: #fff;
}
</style>
