<template>
    <div>
        <div v-if="!langs.length">
            <small><i>Multilingue non configuré</i></small>
        </div>

        <div class="k-flex-row" v-if="defaultLang">
            <span :class="`flag:${defaultLang.id}`" />
            <b-form-input
                class="flex-grow-1 w-auto"
                type="text"
                size="sm"
                :value="menu.name"
                disabled
            />
        </div>
        <div class="k-flex-row mt-2" v-for="lang in langs" :key="lang.id">
            <span :class="`flag:${lang.id}`" />
            <template v-if="translations.hasOwnProperty(lang.id)">
                <b-form-input
                    class="flex-grow-1 w-auto"
                    type="text"
                    size="sm"
                    :value="translations[lang.id]"
                    @change="updateTranslation(lang.id, $event)"
                />
                <b-button
                    size="xs"
                    variant="outline-danger"
                    @click="deleteTranslation(lang.id)"
                >
                    <b-icon-x-circle />
                </b-button>
            </template>
            <template v-else>
                <small class="flex-grow-1 text-center">Non traduit</small>
                <b-button
                    size="xs"
                    variant="outline-kalkin-2"
                    @click="createTranslation(lang.id)"
                >
                    <b-icon-plus-circle />
                </b-button>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        projectId: { type: [String, Number], required: true },
        arbo: { type: Object, required: true },
        menu: { type: Object, required: true },
    },

    data: function() {
        return {
            translations: {},
        };
    },

    computed: {
        defaultLang() {
            const d = this.arbo.Langs.filter(
                l => l.ArboTranslation.isDefault,
            )[0];
            return d || null;
        },

        langs() {
            return this.arbo.Langs.filter(l => !l.ArboTranslation.isDefault);
        },
    },

    watch: {
        async menu() {
            await this.fetchTranslations();
        },
    },

    methods: {
        async fetchTranslations() {
            const raw = await this.$store.dispatch("arbo/getMenuTranslations", {
                projectId: this.projectId,
                menuId: this.menu.id,
            });
            this.translations = (raw || []).reduce((acc, cur) => {
                acc[cur.id] = cur.MenuTranslation.name;
                return acc;
            }, {});
        },

        async createTranslation(langId) {
            await this.$store.dispatch("arbo/createMenuTranslation", {
                projectId: this.projectId,
                menuId: this.menu.id,
                langId,
            });
            await this.fetchTranslations();
            this.$emit("updated");
        },

        async updateTranslation(langId, name) {
            await this.$store.dispatch("arbo/updateMenuTranslation", {
                projectId: this.projectId,
                menuId: this.menu.id,
                langId,
                name,
            });
            await this.fetchTranslations();
        },

        async deleteTranslation(langId) {
            await this.$store.dispatch("arbo/deleteMenuTranslation", {
                projectId: this.projectId,
                menuId: this.menu.id,
                langId,
            });
            await this.fetchTranslations();
            this.$emit("updated");
        },
    },

    async beforeMount() {
        await this.fetchTranslations();
    },
};
</script>
