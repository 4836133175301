<template>
    <div>
        <ArboNode
            class="p-2"
            :arbo="arbo"
            :menu="menu"
            :selected-menu-id="selectedMenuId"
            @action="onAction"
        />
    </div>
</template>

<script>
import ArboNode from "./ArboNode.vue";
export default {
    components: {
        ArboNode,
    },

    props: {
        arbo: Object,
        root: Array,
        selectedMenuId: [String, Number],
    },

    computed: {
        menu() {
            return {
                children: this.root,
            };
        },
    },

    methods: {
        onAction(payload) {
            this.$emit("action", payload);
        },
    },
};
</script>
