<template>
    <div>
        <b-form-group>
            <label>
                Type de menu
            </label>
            <b-form-select
                v-model="localType"
                :options="typeOptions"
                size="sm"
                @change="onChanged"
            />
            <small class="text-secondary">
                <b-icon-question-circle /> Surcharge spécifique pour les menus
                ayant des données
            </small>
        </b-form-group>

        <b-form-group>
            <label>
                Identifiant modèle
            </label>
            <b-form-input
                type="text"
                size="sm"
                v-model="localTemplateId"
                placeholder="Identifiant modèle"
                @change="onChanged"
            />
            <small class="text-secondary">
                <b-icon-question-circle /> Identifiant du menu dans le modèle
            </small>
        </b-form-group>

        <b-form-group>
            <label>
                Surcharge du behaviour
                <b-icon-question-circle-fill
                    id="help-override"
                    class="text-secondary"
                />
            </label>
            <JSONTextArea
                v-model="localOverrideBehaviour"
                @input="onChanged"
                @validate="isOverrideBehaviourValid = $event"
            />
            <b-popover
                target="help-override"
                triggers="hover"
                placement="topleft"
            >
                <template #title>Exemple de surcharge de behaviour</template>
                <pre>{{ overrideBehaviourExample }}</pre>
            </b-popover>
        </b-form-group>

        <b-form-group>
            <label>
                Modules
                <b-icon-question-circle-fill
                    id="help-modules"
                    class="text-secondary"
                />
            </label>
            <JSONTextArea
                v-model="localModules"
                @input="onChanged"
                @validate="isModulesValid = $event"
            />
            <b-popover
                target="help-modules"
                triggers="hover"
                placement="topleft"
            >
                <template #title>Exemple de modules</template>
                <pre>{{ modulesExample }}</pre>
            </b-popover>
        </b-form-group>

        <b-form-group>
            <label>
                Contenu
                <b-icon-question-circle-fill
                    id="help-content"
                    class="text-secondary"
                />
            </label>
            <JSONTextArea
                v-model="localContent"
                @input="onChanged"
                @validate="isContentValid = $event"
            />
            <b-popover
                target="help-content"
                triggers="hover"
                placement="topleft"
            >
                <template #title>Exemple de contenu</template>
                <pre>{{ contentExample }}</pre>
            </b-popover>
        </b-form-group>

        <div class="text-center">
            <small class="text-danger" v-show="!isValid">
                <b-icon-exclamation-circle /> Echec de la sauvegarde, au moins
                un champ est invalide
            </small>
        </div>
    </div>
</template>

<script>
import { get as _get, isEmpty as _isEmpty } from "lodash";
import JSONTextArea from "@/components/ui/item/JSONTextArea";
import MenuData from "@/model/menu";

function _initJSON(source, path) {
    const obj = _get(source, path);
    return obj ? JSON.stringify(obj) : null;
}

export default {
    components: {
        JSONTextArea,
    },

    props: {
        menu: Object,
    },

    data: function() {
        return {
            localTemplateId: this.menu.templateId,
            localOverrideBehaviour: _initJSON(this.menu, "config.behaviour"),
            localModules: _initJSON(this.menu, "config.modules"),
            localContent: _initJSON(this.menu, "config.content"),
            localType: _initJSON(this.menu, "config.type"),
            isOverrideBehaviourValid: true,
            isModulesValid: true,
            isContentValid: true,

            typeOptions: MenuData.getTypeOptions(),

            overrideBehaviourExample: JSON.stringify(
                {
                    menu_item: "ImageAndTextMenuItem",
                },
                null,
                2,
            ),
            modulesExample: JSON.stringify(
                {
                    highlight: { menu: true },
                },
                null,
                2,
            ),
            contentExample: JSON.stringify(
                {
                    icon: "url",
                },
                null,
                2,
            ),
        };
    },

    watch: {
        menu() {
            this.localTemplateId = this.menu.templateId;
            this.localOverrideBehaviour = _initJSON(
                this.menu,
                "config.behaviour",
            );
            this.localModules = _initJSON(this.menu, "config.modules");
            this.localContent = _initJSON(this.menu, "config.content");
            this.localType = _initJSON(this.menu, "config.type");
        },
    },

    computed: {
        isValid() {
            return (
                this.isOverrideBehaviourValid &&
                this.isModulesValid &&
                this.isContentValid
            );
        },
    },

    methods: {
        onChanged() {
            if (
                this.isOverrideBehaviourValid &&
                this.isModulesValid &&
                this.isContentValid
            ) {
                this.save();
            }
        },

        save() {
            const config = {
                ...(this.menu.config || {}),
                behaviour: JSON.parse(this.localOverrideBehaviour) || undefined,
                modules: JSON.parse(this.localModules) || undefined,
                content: JSON.parse(this.localContent) || undefined,
                type: JSON.parse(this.localType) || undefined,
            };
            this.$emit("update-menu", {
                ...this.menu,
                templateId: this.localTemplateId,
                config: _isEmpty(config) ? undefined : config,
            });
        },
    },
};
</script>
